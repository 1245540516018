import React from "react";
import { useCookies } from "react-cookie";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "@mui/material";

export default function Navbar() {
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const navigate = useNavigate();

  const handleLogout = () => {
    removeCookie("user");

    navigate("/login");
  };

  const user = cookies.user;

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="/home">
          <img src="./logogaleno.png" alt="OSSEG" height="25" />
        </a>
     
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto"></ul>

          {user ? (
            <Button
              variant="outlined"
              color="error"
              onClick={handleLogout}
              sx={{
                backgroundColor: "transparent", // Color de fondo cuando no se pasa el mouse
                "&:hover": {
                  backgroundColor: "rgba(255, 0, 0, 0.1)", // Color de fondo al pasar el mouse (ligeramente rojo)
                },
              }}
            >
              Cerrar Sesión
            </Button>
          ) : null}
        </div>
      </div>
    </nav>
  );
}
