import React from "react";
import { Box, Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Header } from "./header.tsx";


export default function Home() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/afiliates");
  };

  return (
    <Paper
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Header />

      <Box textAlign="center" sx={{ position: "relative", top: "-100px" }}>
        <Button variant="contained" color="primary" onClick={handleNavigate}>
          SEGUIMIENTO DE PEDIDOS
        </Button>
      </Box>

      {/* Footer */}
      <Box
        component="footer"
        sx={{
          width: "100%",
          textAlign: "center",
          position: "relative",
          bottom: 0,

          top: "150px",
          backgroundColor: "#f8f9fa",
        }}
      >
        <div className="footer-container">
          <div className="footer-content">
            <div className="footer-text">
              Powered© by{" "}
              <a
                href="https://beeneu.dev"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                Beeneu
              </a>
              . Todos los derechos reservados.
            </div>
          </div>
        </div>
      </Box>
    </Paper>
  );
}
