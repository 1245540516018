import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

interface TextInput {
  disabled?: boolean;
  label?: string;
  handleInputChange?: any;
  onEnter?: () => void;
}

export const TextInput: React.FC<TextInput> = ({
  disabled = false,
  label = "",
  handleInputChange,
  onEnter,
}) => {
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && onEnter) {
      onEnter();
    }
  };

  return (
    <Box
      component="form"
      sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        disabled={disabled}
      />
    </Box>
  );
};
