import { createApi } from "@reduxjs/toolkit/query/react";  
import customFetchBase from "./customFetchBase.tsx";  
import { GenericResponse } from "../../models/responses.tsx";  

export const afiliatesApi = createApi({  
  reducerPath: "afiliadosApi",  
  baseQuery: customFetchBase,  
  endpoints: (builder) => ({  
    getAfiliadosQuery: builder.mutation<GenericResponse, { filters: {}, jwt: any }>({  
      query: ({ filters, jwt }) => ({  
        url: `/afiliates`,  
        method: "POST",  
        credentials: "include",  
        headers: { "Content-Type": "application/json", "Authorization": jwt },  
        body: { ...filters, cardCode: ["C1174", "C1240","C1242","C1248","C1247","C1199","C1239","C1069","C1253","C1273","C1272","C1270","C1274","C1258","C1277","C1275","C1271"] }, // Pasar un arreglo vacío  
      }),  
    }),  
  }),  
});  

export const { useGetAfiliadosQueryMutation } = afiliatesApi;  